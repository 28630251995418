import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';
import VoiceChatIcon from '@material-ui/icons/VoiceChat';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { CircularProgress, Hidden, Switch, Grid } from "@material-ui/core";
import Loader from "react-loader-advanced";
import Clock from "./Clock";
import ConfirmModal from "../Common/CommonModal";
import { removeAuthObjects, toastError, toastSuccess } from "../../utils";
import appLogo from "../../assets/img/Fronic_logo_black.png";
import { Link } from "react-router-dom";
import * as commonAction from "../Common/actions";

//icons
import HomeIcon from "@material-ui/icons/Home";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import ContactsIcon from "@material-ui/icons/Contacts";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import CustomAddModal from "../Common/CustomAddModal";
import GavelIcon from "@material-ui/icons/Gavel";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import HearingIcon from "@material-ui/icons/Hearing";
import MusicNote from "@material-ui/icons/MusicNote";
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';

const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    background:
      "linear-gradient(90deg, rgba(19,153,121,1) 0%, rgba(18,180,161,1) 45%, rgba(5,150,179,1) 100%)",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: "300ms",
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: "300ms",
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: "300ms",
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: "300ms",
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  imageDiv: {
    width: "100%",
    height: "40px",
    display: "flex",
  },
  imglogo: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));

const initialListItems = [
  {
    label: "Videos",
    icon: <HomeIcon />,
    path: "/",
  },
  {
    label: "News",
    icon: <AnnouncementIcon />,
    path: "/news",
  },
  {
    label: "Review Releases",
    icon: <NewReleasesIcon />,
    path: `/releases/review`,
  },
  {
    label: "Approved Releases",
    icon: <NewReleasesIcon />,
    path: "/release/approved",
  },
  {
    label: "Rejected Releases",
    icon: <NewReleasesIcon />,
    path: "/release/rejected",
  },
  {
    label: "Takendown Releases",
    icon: <RemoveCircleOutlineIcon />,
    path: "/release/takendown",
  },
  {
    label: "Video Releases",
    icon: <SlowMotionVideoIcon />,
    path: "/video_releases/review",
  },
  {
    label: "Video History",
    icon: <VoiceChatIcon />,
    path: "/video_releases/history",
  },
  {
    label: "UGC Claims",
    icon: <GavelIcon />,
    path: "/ugc_claims",
  },
  {
    label: "Profile Linking",
    icon: <SupervisorAccountIcon />,
    path: "/profile_linking",
  },
  {
    label: "Playlist Pitching",
    icon: <HearingIcon />,
    path: "/playlist_pitching",
  },
  {
    label: "Lyrics Distribution",
    icon: <MusicNote />,
    path: "/lyrics_distribution",
  },
  {
    label: "Whitelisting",
    icon: <PlaylistPlayIcon />,
    path: "/whitelisting",
  },
  {
    label: "User Details",
    icon: <RecentActorsIcon />,
    path: "/user_details",
  },
  {
    label: "Artists",
    icon: <ContactsIcon />,
    path: "/artists",
  },
  {
    label: "Report Requests",
    icon: <AssignmentIcon />,
    path: "/report_requests",
  },
  {
    label: "Tickets",
    icon: <ConfirmationNumberIcon />,
    path: "/tickets",
  },
  {
    label: "Requested Payouts",
    icon: <MonetizationOnIcon />,
    path: "/payout",
  },
  {
    label: "Notifications",
    icon: <NotificationsActiveIcon />,
    path: "/notifications",
  },
  {
    label: "Invite",
    icon: <PersonAddIcon />,
    path: "/invite",
  },
  {
    label: "Logout",
    icon: <MeetingRoomIcon />,
    path: "/login",
  },
];

export default function MainContainer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [loader, setLoader] = React.useState(false);
  const [confirmOpen, setConfimOpen] = React.useState(false);
  const [listItems, setListItems] = React.useState([...initialListItems]);
  const [awayStatus, setAwayStatus] = React.useState(false);
  const [awayText, setAwayText] = React.useState("");
  const [openAwayText, setOpenAwayText] = React.useState(false);

  React.useEffect(() => {
    commonAction
      .countMeterAdmin()
      .then((res) => {
        let updatedList = [];
        const {
          in_review = 0,
          open_tickets = 0,
          reports_requested = 0,
          withdrawl_requested = 0,
          ugc_claim = 0,
          profile_linking = 0,
          pitch_playlist = 0,
          distribute_lyrics = 0,
          video_releases = 0,
          whitelisting = 0
        } = res.body || {};
        for (let i = 0; i < initialListItems.length; i++) {
          const item = initialListItems[i];
          if (item.label === "Review Releases")
            updatedList.push({
              ...item,
              label: `${item.label} (${in_review})`,
            });
          else if (item.label === "Tickets")
            updatedList.push({
              ...item,
              label: `${item.label} (${open_tickets})`,
            });
          else if (item.label === "Report Requests")
            updatedList.push({
              ...item,
              label: `${item.label} (${reports_requested})`,
            });
          else if (item.label === "Requested Payouts")
            updatedList.push({
              ...item,
              label: `${item.label} (${withdrawl_requested})`,
            });
          else if (item.label === "UGC Claims")
            updatedList.push({
              ...item,
              label: `${item.label} (${ugc_claim})`,
            });
          else if (item.label === "Profile Linking")
            updatedList.push({
              ...item,
              label: `${item.label} (${profile_linking})`,
            });
          else if (item.label === "Playlist Pitching")
            updatedList.push({
              ...item,
              label: `${item.label} (${pitch_playlist})`,
            });
            else if (item.label === "Lyrics Distribution")
            updatedList.push({
              ...item,
              label: `${item.label} (${distribute_lyrics})`,
            });
            else if (item.label === "Video Releases")
            updatedList.push({
              ...item,
              label: `${item.label} (${video_releases})`,
            });
            else if (item.label === "Whitelisting")
              updatedList.push({
                ...item,
                label: `${item.label} (${whitelisting})`,
              });
          else updatedList.push({ ...item });
        }
        setListItems(updatedList);
      })
      .catch(() => {});
    commonAction.checkAwayStatus().then((res) => {
      setAwayStatus(
        res && res.body && res.body.status && res.body.status === "yes"
          ? true
          : false || false
      );
      setAwayText((res && res.body && res.body.text) || "");
    });
  }, []);

  React.useEffect(() => {
    window.addEventListener("resize", (event) => {
      // console.log(document.body.clientWidth + ' wide by ' + document.body.clientHeight + ' high');
      if (document.body.clientWidth < 700) {
        if (open) setOpen(false);
      } else {
        if (!open) setOpen(true);
      }
    });
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, [open]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const header = () => {
    const { path } = props.match;
    switch (path) {
      case "/":
        return "Videos";
      case "/news":
        return "News";
      case "/releases/review":
        return "Review Releases";
      case "/release/approved":
        return "Approved Releases";
      case "/release/rejected":
        return "Rejected Releases";
      case "/user_details":
        return "User Details";
      case "/report_requests":
        return "Report Requests";
      case "/tickets":
        return "Tickets";
      case "/payout":
        return "Requested Payouts";
      case "/notifications":
        return "Notifications";
      case "/ugc_claims":
        return "UGC Claims";
      case "/profile_linking":
        return "Profile Linking";
      case "/playlist_pitching":
        return "Playlist Pitching";
      default: {
        return "";
      }
    }
  };

  const handleConfirmClickOpen = () => {
    setConfimOpen(true);
  };

  const handleConfirmClose = () => {
    setConfimOpen(false);
  };

  const onConfirmLogout = () => {
    onSetLoader(true);
    removeAuthObjects();
    // actions.clearStore()
    setTimeout(() => {
      onSetLoader(false);
      return props.history.push("/login");
    }, 1000);
  };

  const onChangeAwayStatus = (checked = false) => {
    // const { checked } = e.target
    onSetLoader(true);
    commonAction
      .changeAwayStatusAdmin({
        status: checked ? "yes" : "no",
        text: awayText || "",
      })
      .then((res) => {
        onSetLoader(false);
        setAwayStatus(checked);
        setOpenAwayText(false);
        toastSuccess(res.message || "Status changed successfully");
      })
      .catch((err) => {
        onSetLoader(false);
        toastError(err.message);
      });
  };

  const onSetLoader = (action) => {
    setLoader(action);
  };

  const onSetAwayText = (e) => {
    if (!awayStatus) {
      setOpenAwayText(true);
    } else {
      onChangeAwayStatus(false);
    }
  };

  const LoaderStyle = {
    height: "100%",
    overflow: "hidden",
  };

  return (
    <Loader
      className="main-loader"
      style={loader ? LoaderStyle : null}
      message={<CircularProgress size={50} style={{ color: "white" }} />}
      show={loader}
    >
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <div
              style={{
                width: "100%",
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <Typography variant="h6" noWrap style={{ alignSelf: "center" }}>
                {header()}
              </Typography>
              <Hidden only={["xs"]}>
                <div
                  style={{
                    alignSelf: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <p>({awayStatus ? "Away" : "Available"})</p>
                  <Switch
                    checked={awayStatus}
                    style={{ color: "#65ffcd" }}
                    onChange={onSetAwayText}
                  />
                  <p
                    style={{
                      paddingRight: "23px",
                      marginRight: "23px",
                      fontSize: "18px",
                      borderRight: "1px solid #d8d8d8",
                    }}
                  >
                    WELCOME, ADMIN.
                  </p>
                  <Clock />
                </div>
              </Hidden>
              <Hidden only={["sm", "md", "lg", "xl"]}>
                <div
                  style={{
                    alignSelf: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Switch
                    checked={awayStatus}
                    style={{ color: "#65ffcd" }}
                    onChange={onSetAwayText}
                  />
                </div>
              </Hidden>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <div className={classes.imageDiv}>
              <img
                className={classes.imglogo}
                src={appLogo}
                alt=""
                onClick={() => props.history.push("/")}
              />
              {/* <h2 style={{ fontWeight: '400', textAlign: 'center', fontSize: '23px', margin: 'auto' }}>TheFoodSquad</h2> */}
            </div>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            {listItems.map((text, index) => {
              if (text.label === "Logout") {
                return (
                  <ListItem
                    style={{
                      backgroundColor:
                        props.location.pathname === text.path ? "#dedede" : "",
                    }}
                    button
                    key={text.label}
                    onClick={handleConfirmClickOpen}
                  >
                    <ListItemIcon style={{ minWidth: 40 }}>
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText primary={text.label} />
                  </ListItem>
                );
              } else {
                return (
                  <Link
                    className="link-color-black"
                    key={text.label}
                    to={text.path}
                  >
                    <ListItem
                      style={{
                        backgroundColor:
                          props.location.pathname === text.path
                            ? "#dedede"
                            : "",
                      }}
                      button
                    >
                      <ListItemIcon style={{ minWidth: 40 }}>
                        {text.icon}
                      </ListItemIcon>
                      <ListItemText primary={text.label} />
                    </ListItem>
                  </Link>
                );
              }
            })}
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {/* {props.children} */}
          {React.cloneElement(props.children, { setLoader: onSetLoader })}
        </main>
        <ConfirmModal
          message={`Are you sure you want to Logout?`}
          open={confirmOpen}
          title={`Confirm Logout`}
          onSubmitConfirm={onConfirmLogout}
          handleClose={handleConfirmClose}
        ></ConfirmModal>

        <CustomAddModal
          open={openAwayText}
          saveLabel="Set Away"
          title="Add Away Text"
          handleClose={() => setOpenAwayText(false)}
          onSubmitConfirm={() => onChangeAwayStatus(true)}
        >
          <Grid container style={{ height: "330px", display: "block" }}>
            <Grid item xs={12} style={{ height: "calc(100% - 60px)" }}>
              <textarea
                className="text-area-input"
                placeholder="Please add a text"
                value={awayText}
                onChange={(e) => setAwayText(e.target.value)}
              />
            </Grid>
          </Grid>
        </CustomAddModal>
      </div>
    </Loader>
  );
}
