import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import Login from "./containers/Auth"
import News from './containers/News';
import { isLoggedIn } from './utils';
import Tickets from './containers/Tickets';
import MainContainer from "./containers/Main"
import Dashboard from './containers/Homepage';
import NewReleases from './containers/Releases';
import NewVideoReleases from './containers/VideoReleases';
import VideoReleasesHistory from './containers/VideoReleases/VideoReleasesHistory';
import ViewVideoReleases from './containers/VideoReleases/ViewVideoReleases';
import ReportsRequests from './containers/Reports';
import UserDetails from './containers/UserDetails.js';
import ViewReleases from './containers/Releases/ViewReleases';
import ViewUserDetails from './containers/UserDetails.js/ViewUserDetails';
import ViewTickets from './containers/Tickets/ViewTickets';
import Artists from './containers/Artists';
import ApprovedRelease from './containers/Releases/ApprovedRelease';
import RejectedRelease from './containers/Releases/RejectedRelease';
import Invite from './containers/Invite';
import Payout from './containers/Payout';
import TakendownRelease from './containers/Releases/TakedownRelease';
import Notification from './containers/Notification';
import UGC from './containers/UgcClaims';
import Linking from './containers/ProfileLinking';
import Pitching from './containers/PlaylistPitching';
import Lyrics from './containers/Lyrics';
import Whitelisting from './containers/WhiteListing/index.js';

const PriavteRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn() ? (
        <MainContainer {...props}>
          <Component {...props} />
        </MainContainer>
      )
        : (
          <Redirect to='/login' />
        )
    }
  />
);

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => isLoggedIn() ? <Redirect to="/" /> : <Component {...props} />
    }
  />
);

export default function App() {
  return (
    <Router>
      <Switch>
      <Route exact path="/static">
          <Redirect to="/login" />
        </Route>
        <PublicRoute exact path='/login' component={Login} />
        <PriavteRoute exact path="/news" component={News} />
        <PriavteRoute exact path="/" component={Dashboard} />
        <PriavteRoute exact path="/tickets" component={Tickets} />
        <PriavteRoute exact path="/invite" component={Invite} />
        <PriavteRoute exact path="/payout" component={Payout} />
        <PriavteRoute exact path="/view_tickets/:id" component={ViewTickets} />
        <PriavteRoute exact path="/user_details" component={UserDetails} />
        <PriavteRoute exact path="/releases/review" component={NewReleases} />
        <PriavteRoute exact path="/release/approved" component={ApprovedRelease} />
        <PriavteRoute path="/release/rejected" component={RejectedRelease} />
        <PriavteRoute path="/release/takendown" component={TakendownRelease} />
        <PriavteRoute exact path="/artists" component={Artists} />
        <PriavteRoute exact path="/report_requests" component={ReportsRequests} />
        <PriavteRoute exact path="/notifications" component={Notification} />
        <PriavteRoute exact path="/view_releases/:id" component={ViewReleases} />
        <PriavteRoute exact path="/view_user_details/:id" component={ViewUserDetails} />
        <PriavteRoute exact path="/ugc_claims" component={UGC} />
        <PriavteRoute exact path="/profile_linking" component={Linking} />
        <PriavteRoute exact path="/playlist_pitching" component={Pitching} />
        <PriavteRoute exact path="/lyrics_distribution" component={Lyrics} />
        <PriavteRoute exact path="/video_releases/review" component={NewVideoReleases} />
        <PriavteRoute exact path="/video_releases/history" component={VideoReleasesHistory} />
        <PriavteRoute exact path="/view_video_release/:id" component={ViewVideoReleases} />
        <PriavteRoute exact path="/whitelisting" component={Whitelisting} />
      </Switch>
    </Router>
  );
}
