import { apiGet, apiPost } from "../../utils"

export function getNewReleasesListAdminAPI(data) {
    return apiGet(`/api/getNewReleasesListAdmin`, data)
}

export function getNewReleaseByIdAdminAPI(id) {
    return apiGet(`/api/getNewReleaseByIdAdmin?release_id=${id}`)
}

export function searchInReleasesAPI(filters) {
    const { type = '', search = '' } = filters || {}
    return apiGet(`/api/searchInReleases?type=${type}&search=${search}`)
}

export function changeReleaseStatusAdminAPI(data) {
    return apiPost(`/api/changeReleaseStatusAdmin`, data)
}

export function rejectReleaseAudioAdminAPI(data) {
    return apiPost(`/api/rejectReleaseAudioAdmin`, data)
}

export function deleteRejectedReleaseAPI(data) {
    return apiPost(`/api/deleteRejectedRelease`, data)
}

export function rejectReasonsAdminAPI(data) {
    return apiGet(`/api/rejectReasonsAdmin`, data)
}

export function ticketReasonsAdminAPI(data) {
    return apiGet(`/api/ticketReasonsAdmin`, data)
}

export function getUGCClaimRequestAdminAPI(data) {
    return apiGet(`/api/getUGCClaimRequestAdmin`, data)
}

export function getUGCClaimOldAdminAPI(data) {
    return apiGet(`/api/getUGCClaimOldAdmin`, data)
}

export function deleteFileFromServerAPI(data) {
    return apiPost(`/api/deleteFileFromServer`, data)
}

export function getProfileLinkingRequestAdminAPI(data) {
    return apiGet(`/api/getProfileLinkingRequestAdmin`, data)
}

export function getProfileLinkingOldAdminAPI(data) {
    return apiGet(`/api/getProfileLinkingOldAdmin`, data)
}

export function getPitchRequestAdminAPI(data) {
    return apiGet(`/api/getPitchRequestAdmin`, data)
}

export function getPitchOldAdminAPI(data) {
    return apiGet(`/api/getPitchOldAdmin`, data)
}

export function updateUGCstatusAdminAPI(data) {
    return apiPost(`/api/updateUGCstatusAdmin`, data)
}

export function updateProfileLinkingstatusAdminAPI(data) {
    return apiPost(`/api/updateProfileLinkingstatusAdmin`, data)
}

export function updatePitchStatusAdminAPI(data) {
    return apiPost(`/api/updatePitchStatusAdmin`, data)
}

export function getVReleasesAdminAPI(data) {
    return apiGet(`/api/getVReleasesAdmin`, data)
}

export function getVReleaseByIdAdminAPI(data) {
    return apiGet(`/api/getVReleaseByIdAdmin`, data)
}

export function changeVideoReleaseStatusAdminAPI(data) {
    return apiPost(`/api/changeVideoReleaseStatusAdmin`, data)
}

export function deleteVideoReleaseAdminAPI(data) {
    return apiPost(`/api/deleteVideoReleaseAdmin`, data)
}

export function updateVThumbnailAdminAPI(data) {
    return apiPost(`/api/updateVThumbnailAdmin`, data)
}

export function deleteVThumnailFromServerAPI(data) {
    return apiPost(`/api/deleteVThumnailFromServer`, data)
}

export function getLyricsDestributionRequestAdminAPI(data) {
    return apiGet(`/api/getLyricsDestributionRequestAdmin`, data)
}

export function getLyricsOldAdminAPI(data) {
    return apiGet(`/api/getLyricsOldAdmin`, data)
}

export function updateLyricsStatusAdminAPI(data) {
    return apiPost(`/api/updateLyricsStatusAdmin`, data)
}

export function getWhitelistingRequestAdminAPI(data) {
    return apiGet(`/api/getWhitelistingRequestAdmin`, data)
}
export function updateWhitelistingStatusAdminAPI(data) {
    return apiPost(`/api/updateWhitelistingStatusAdmin`, data)
}
export function getWhitelistingOldAdminAPI(data) {
    return apiGet(`/api/getWhitelistingOldAdmin`, data)
}